import React from "react";
import { Route as BaseRoute, withRouter, Switch } from "react-router-dom";
import Admin from "./admin/Admin";

class ScrollToTopRoute extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { component: Component, ...rest } = this.props;

    return <BaseRoute {...rest} render={(props) => <Component {...props} />} />;
  }
}

const Route = withRouter(ScrollToTopRoute);

function App() {
  return (
    <Switch>
      <Route exact path="/" component={Admin} />
    </Switch>
  );
}

export default App;
