import React, { useState, useEffect } from "react";
import styled from "styled-components";
import apiRoot from "../apiRoot";
import { Link as BaseLink } from "react-router-dom";

async function getData(setGoal, setDonations, password, setPassword, setError) {
  const data = await (
    await fetch(apiRoot + "/donations?adminkey=" + password)
  ).json();
  if (!data.success) return setError(data.message);
  setPassword(password);
  localStorage.setItem("password", password);
  setDonations(data.donations);
  const data2 = await (await fetch(apiRoot + "/goal")).json();
  setGoal(data2.goal);
}

async function addDonation(name, amount, password, setError, reset) {
  const response = await (
    await fetch(apiRoot + "/addDonation", {
      method: "POST",
      body: JSON.stringify({ name, amount, adminkey: password }),
      headers: { "content-type": "application/json" },
    })
  ).json();
  if (!response.success) return setError(response.error);
  reset();
}

async function changeGoal(goal, password, setError, reset) {
  const response = await (
    await fetch(apiRoot + "/changeDonationGoal", {
      method: "POST",
      body: JSON.stringify({ goal, adminkey: password }),
      headers: { "content-type": "application/json" },
    })
  ).json();
  if (!response.success) return setError(response.error);
  reset();
}

async function getWinner(setWinner, password, setError) {
  const response = await (
    await fetch(apiRoot + "/chooseWinner", {
      method: "POST",
      body: JSON.stringify({ adminkey: password }),
      headers: { "content-type": "application/json" },
    })
  ).json();
  if (!response.success) return setError(response.error);
  setWinner(response.winner);
}

const Form = styled.form`
  display: flex;
  padding: 40px;
  flex-direction: column;
  margin: 0 auto;
  max-width: 300px;
`;

const Container = styled.div`
  display: flex;
  padding: 40px;
  flex-direction: column;
  margin: 0 auto;
  max-width: 600px;
`;

const InputComponent = styled.input`
  border: 1px solid #bbb;
  border-radius: 4px;
  font-size: 16px;
  padding: 8px 12px;
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box;
  :focus {
    outline: none;
    border-color: #888;
  }
`;

const Button = styled.button`
  border: none;
  background: #3498db;
  color: white;
  font-size: 16px;
  padding: 8px 12px;
  font-size: 16px;
  border-radius: 4px;
  font-weight: 500;
  width: 100%;
  margin-top: 10px;
  :hover {
    background: #2275a4;
    cursor: pointer;
  }
`;

const ErrorMsg = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: #e74c3c;
  margin-top: 10px;
`;

const Divider = styled.div`
  height: 1px;
  width: 80%;
  margin: 40px auto 20px auto;
  background: rgba(0, 0, 0, 0.2);
`;

const TwoPane = styled.div`
  display: flex;
  div {
    flex: 1;
  }
  h5,
  h3 {
    font-weight: 400;
  }
`;

const TwoPaneDivider = styled.div`
  flex: 0 !important;
  margin-right: 40px;
`;

const Input = (props) => (
  <InputComponent
    value={props.get}
    onChange={(e) => props.set(e.target.value)}
    ref={props.passRef}
    {...props}
  />
);

const Admin = function () {
  const [error, setError] = useState("");
  const [ptmp, setPtmp] = useState("");
  const [name, setName] = useState("");
  const [amount, setAmount] = useState("");
  const [goal, setGoal] = useState("");
  const [tempGoal, setTempGoal] = useState("");
  const [winner, setWinner] = useState("");
  const [donations, setDonations] = useState();

  useEffect(() => {
    if (localStorage.getItem("password") && !donations)
      getData(
        setGoal,
        setDonations,
        localStorage.getItem("password"),
        (p) => {},
        setError
      );
  }, [donations]);

  return (
    <div>
      {!localStorage.getItem("password") && (
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            getData(
              setGoal,
              setDonations,
              ptmp,
              (p) => localStorage.setItem("password", p),
              setError
            );
          }}
        >
          <h2>Admin Login</h2>
          <Input placeholder="Password" get={ptmp} set={setPtmp} />
          <Button type="submit">Log in</Button>
          {error && <ErrorMsg>{error}</ErrorMsg>}
        </Form>
      )}
      {localStorage.getItem("password") && (
        <Container>
          <h2>FORM Donation Admin Dashboard</h2>
          <Button
            onClick={() => {
              localStorage.setItem("password", "");
              setGoal(undefined);
              setDonations(undefined);
            }}
          >
            Log out
          </Button>
          <Divider />
          <TwoPane>
            <div>
              <h4>Set Donation Goal</h4>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  changeGoal(
                    Number(tempGoal),
                    localStorage.getItem("password"),
                    setError,
                    () => setTempGoal("")
                  );
                  setGoal(Number(tempGoal));
                }}
              >
                <Input
                  placeholder="Donation goal"
                  get={tempGoal}
                  set={setTempGoal}
                />
                <Button type="submit">Change goal</Button>
              </form>
            </div>
            <TwoPaneDivider />
            <div>
              <h5>Current donation goal</h5>
              <h3>${goal}</h3>
            </div>
          </TwoPane>
          <Divider />
          <TwoPane>
            <div>
              <h4>Donations</h4>
            </div>
            <TwoPaneDivider />
            <div>
              <h5>Donations</h5>
              {donations &&
                donations.map((i) => {
                  let date = new Date(i.created_at);
                  let get = (n) =>
                    String(n).length === 1 ? "0" + String(n) : String(n);
                  return (
                    <div key={i.id}>
                      {i.name} - ${i.amount} - {get(date.getHours())}:
                      {get(date.getMinutes())}:{get(date.getSeconds())}
                    </div>
                  );
                })}
            </div>
          </TwoPane>
        </Container>
      )}
    </div>
  );
};

export default Admin;
